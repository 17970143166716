<template>
  <div class="clearfix">
    <b-table striped small hover :items="lemmasData" :per-page="perPage" :current-page="currentPage" selectable @row-selected="onRowSelected">
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'Lemma', params: {id: data.item.action.id} }">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
      <template #cell(comment)="data">
        <span v-html="data.item.comment"></span>
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaTable',
    props: ['lemmas', 'editMode', 'showTotal'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.lemmas.length,
        perPage: 10,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    computed: {
      lemmasData(){
        return this.lemmas.map(lemma => {
          return {
            ...config.editable && {id: lemma.id},
            lemmaForm: lemma.lemmaForm,
            languageCode: lemma.languageCode,
            comment: lemma.comment,
            action: {
              id: lemma.id
            }
          }
        })
      }
    },
    methods: {
      onRowSelected(item, index, event){
        console.log('row-selected', item, index, event)
      },
      deleteRow(event, lemmaId){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Lemma deleted`
        // })
        this.$emit('delete', lemmaId)
      }
    }
  }
</script>
