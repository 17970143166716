<template>
  <div class="references">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <b-input-group size="sm">
            <b-form-input
              id="search"
              size="sm"
              v-model="query"
              @keyup="getSearchResults()"
              autocomplete="off"
              placeholder="Search references"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="$emit('close')">Close</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <ul v-if="references.length || allowNew" class="results-ul">
            <li v-for="reference in references" :key="reference.id">
              <a href="#" @click="selectReference($event, reference)" v-html="reference.display"></a>
            </li>
            <li v-if="allowNew && clearQuery" class="mt-2">
              <a href="#" @click="newReference($event)">
                <small>Create new reference</small> <b>{{ clearQuery }}</b>
              </a>
              <b-modal id="modal-new-reference" title="New reference" ok-title="Save" @ok="saveReference" size="lg">
                <ReferenceNew :reference="reference" />
              </b-modal>
            </li>
          </ul>
          <span v-else>No results</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  .results-ul {
    list-style-type: '';
    padding-left: 10px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import ReferenceNew from '@/components/ReferenceNew.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'ReferenceAutocomplete',
    props: ['allowNew', 'exclude'],
    data() {
      this.$store.state.reference.references = []
      return {
        query: "",
        timer: null,
        reference: {
          id: null,
          spelling: ''
        }
      }
    },
    components: {
      ReferenceNew
    },
    mounted() {
      this.$store.state.reference.references = []
    },
    computed: {
      ...mapState({
        references: state => state.reference.references
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectReference(event, reference){
        event.preventDefault()
        this.$emit('select', reference, this.references)
        this.$store.state.reference.references = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchReferences', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      },
      newReference(event){
        event.preventDefault()
        this.reference.spelling = this.query
        this.$bvModal.show('modal-new-reference')
      },
      saveReference(){
        this.$store.dispatch('createReference', HelpStore.item).then((response) => {
          this.$emit('select', response.data, this.reference)
          this.query = ''
        })
      }
    }
  }
</script>
