<template>
  <div>
    <b-form-group
      id="lemma-form-group"
      label="Lemma form:"
      label-for="lemma-form"
    >
      <b-form-input
        id="lemma-form"
        v-model="model.lemmaForm"
        placeholder="Lemma form"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="public-comment-group"
      label="Public comment:"
      label-for="public-comment"
    >
      <b-form-textarea
        id="public-comment"
        placeholder="Public comment"
        v-model="model.publicComment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-group"
      label="Comment:"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="part-of-speech-group"
      label="Part of Speech:"
      label-for="part-of-speech"
    >
      <PartOfSpeechSelect @select="selectPartOfSpeech" />
    </b-form-group>

    <b-form-group
      id="language-code-group"
      label="Language:"
      label-for="language-code"
    >
      <LanguageSelect :language="model.language" @select="selectLanguage" :key="index" />
    </b-form-group>

    <b-form-group
      id="scripta-group"
      label="Scripta:"
      label-for="scripta"
    >
      <ScriptaSelect :languageCode="model.languageCode" @select="selectScripta" />
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'
  import PartOfSpeechSelect from '@/components/Dictionary/PartOfSpeechSelect.vue'
  import ScriptaSelect from '@/components/Main/ScriptaSelect.vue'
  import LanguageSelect from '@/components/Main/LanguageSelect.vue'

  export default {
    name: 'LemmaNewEdit',
    props: ['lemma'],
    data(){
      HelpStore.item = this.lemma
      return {
        // TODO: fetch from API
        model: (this.lemma ? this.lemma : {
          id: null,
          lemmaForm: '',
          publicComment: '',
          comment: '',
          languageCode: null
        }),
        index: 0
      }
    },
    updated(){
      HelpStore.item = this.model
    },
    components: {
      PartOfSpeechSelect,
      ScriptaSelect,
      LanguageSelect
    },
    methods: {
      selectPartOfSpeech(partofspeeches){
        this.model['partofspeeches'] = partofspeeches
        HelpStore.item = this.model
      },
      selectScripta(scriptas){
        this.model['scriptas'] = scriptas
        if (scriptas.length > 0 && scriptas[0].languageCode && this.model.languageCode == null){
          this.model.languageCode = scriptas[0].languageCode
          this.model.language = scriptas[0].language
          this.index += 1
        }
        HelpStore.item = this.model
      },
      selectLanguage(language){
        this.model.languageCode = null
        this.model.language = null
        if (language){
          this.model.languageCode = language.code
          this.model.language = language
        }
        HelpStore.item = this.model
      }
    }
  }
</script>
