<template>
  <div class="partofspeeches" id="select-partofspeech">
    <Select2 @select="select($event)" v-model="selectedItems" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'PartOfSpeechSelect',
    props: {
      'exclude': {default: () => []},
      'selected': {default: () => []}
    },
    components: {
      Select2
    },
    data(){
      return {
        selectedItems: this.selected.map(item => item.id),
        selectedObjs: this.selected
      }
    },
    mounted() {
      if (this.selected){
        this.selected.forEach(partofspeech => {
          this.$refs.select2.select2.append(
            `<option selected value="${partofspeech.id}">${partofspeech.name}</option>`
          ).trigger('change')
        })
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a part of speech",
          allowClear: true,
          width: '100%',
          multiple: true,
          dropdownParent: "#select-partofspeech",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/partofspeeches/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(partofspeech => {
                  return {
                    id: partofspeech.id,
                    text: partofspeech.name,
                    partofspeech: partofspeech
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.selectedObjs = this.selectedObjs.filter(partofspeech => this.selectedItems.includes(partofspeech.id))
        if (event.selected){
          this.selectedObjs.push(event.partofspeech)
        }
        this.$emit('select', this.selectedObjs)
      }
    }
  }
</script>
