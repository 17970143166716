<template>
  <div class="languages" id="select-language">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'LanguageSelect',
    props: ['exclude', 'language'],
    components: {
      Select2
    },
    mounted() {
      if (this.language){
        this.$refs.select2.select2.append(
          `<option selected value="${this.language.code}">${this.language.name}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a language",
          allowClear: true,
          width: '100%',
          // multiple: true,
          dropdownParent: "#select-language",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/languages/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(language => {
                  return {
                    id: language.code,
                    text: language.name,
                    language: language
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', (event.selected ? event.language : null))
      }
    }
  }
</script>
