<template>
  <div class="lemmas">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <b-input-group size="sm">
            <b-form-input
              id="search"
              size="sm"
              v-model="query"
              @keyup="getSearchResults()"
              autocomplete="off"
              placeholder="Search lemma"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="$emit('close')">Close</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <ul v-if="lemmas.results && lemmas.results.length > 0 || allowNew" class="results-ul">
            <li v-for="lemma in lemmas.results" :key="lemma.id">
              <a href="#" @click="selectLemma($event, lemma)">{{ lemma.lemmaForm }} ({{ lemma.languageCode }}) / {{ lemma.articleMainLemmaForm }} {{ lemma.articleDictionaryAcronym }}</a>
            </li>
            <li v-if="allowNew && clearQuery" class="mt-2">
              <a href="#" @click="newLemma($event)">
                <small>Create new lemma</small> <b>{{ clearQuery }}</b>
              </a>
            </li>
          </ul>
          <span v-else-if="showNoResults">No results</span>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-new-lemma" title="New lemma" ok-title="Save" @ok="lemmaSave" >
      <LemmaNewEdit :lemma="null" />
    </b-modal>
  </div>
</template>

<style scoped>
  .results-ul {
    list-style-type: '';
    padding-left: 10px;
  }
</style>

<script>
  let timer
  import { mapState } from 'vuex'
  import LemmaNewEdit from '@/components/Lemma/NewEdit.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'LemmaAutocomplete',
    props: ['allowNew', 'withoutArticle', 'hideNoResults'],
    components: {
      LemmaNewEdit
    },
    data() {
      this.$store.state.lemma.lemmas = []
      return {
        showNoResults: !(this.hideNoResults === true),
        query: "",
        withoutArticleFlag: this.withoutArticle === true
        // allowAddNew: (this.allowNew === true ? true : false)
      }
    },
    mounted() {
      this.$store.state.lemma.lemmas = []
    },
    computed: {
      ...mapState({
        lemmas: state => state.lemma.lemmas
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectLemma(event, lemma){
        event.preventDefault()
        this.$emit('select', lemma)
        this.$store.state.lemma.lemmas = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(timer);
        // let that = this
        timer = setTimeout(() => {
          this.$store.dispatch('searchLemmas', {
            query: this.query.toLowerCase(),
            withoutArticle: this.withoutArticleFlag
          })
        }, 400)
      },
      newLemma(event){
        event.preventDefault()
        this.$bvModal.show('modal-new-lemma')
      },
      lemmaSave(){
        this.$store.dispatch('createLemma', HelpStore.item).then(response => {
          this.$emit('select', response.data)
        })
      }
    }
  }
</script>
