<template>
  <div class="occurrences">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <b-input-group size="sm">
            <b-form-input
              id="search"
              size="sm"
              v-model="query"
              @keyup="getSearchResults()"
              autocomplete="off"
              placeholder="Search occurrences"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="$emit('close')">Close</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <ul v-if="occurrences.length || allowNew" class="results-ul">
            <li v-for="occurrence in occurrences" :key="occurrence.id">
              <a href="#" @click="selectOccurrence($event, occurrence)" v-html="occurrence.display"></a>
            </li>
            <li v-if="allowNew && clearQuery" class="mt-2">
              <a href="#" @click="newOccurrence($event)">
                <small>Create new occurrence</small> <b>{{ clearQuery }}</b>
              </a>
              <b-modal id="modal-new-occurrence" title="New occurrence" ok-title="Save" @ok="saveOccurrence" size="lg">
                <OccurrenceNew :occurrence="occurrence" />
              </b-modal>
            </li>
          </ul>
          <span v-else>No results</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  .results-ul {
    list-style-type: '';
    padding-left: 10px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import OccurrenceNew from '@/components/OccurrenceNew.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'OccurrenceAutocomplete',
    props: ['allowNew', 'exclude'],
    data() {
      this.$store.state.occurrence.occurrences = []
      return {
        query: "",
        timer: null,
        occurrence: {
          id: null,
          spelling: ''
        }
      }
    },
    components: {
      OccurrenceNew
    },
    mounted() {
      this.$store.state.occurrence.occurrences = []
    },
    computed: {
      ...mapState({
        occurrences: state => state.occurrence.occurrences
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectOccurrence(event, occurrence){
        event.preventDefault()
        this.$emit('select', occurrence, this.occurrence)
        this.$store.state.occurrence.occurrences = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchOccurrences', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      },
      newOccurrence(event){
        event.preventDefault()
        this.occurrence.spelling = this.query
        this.$bvModal.show('modal-new-occurrence')
      },
      saveOccurrence(){
        this.$store.dispatch('createOccurrence', HelpStore.item).then((response) => {
          this.$emit('select', response.data, this.occurrence)
          this.query = ''
        })
      }
    }
  }
</script>
