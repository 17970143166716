<template>
  <div>
    <!-- <b-form-group
      id="siglum-group"
      label="Siglum"
      label-for="siglum"
    >
      <b-form-input
        id="siglum"
        v-model="model.siglum"
        placeholder="Siglum"
      ></b-form-input>
    </b-form-group> -->
    <b-form-group
      id="lemma-group"
      label="Lemma"
      label-for="lemma"
    >
      <LemmaSelect @select="selectLemma" />
    </b-form-group>

    <b-form-group
      id="surface-form-group"
      label="Surface Form"
      label-for="surface-form"
    >
      <b-form-textarea
        id="surface-form"
        placeholder="Surface Form"
        v-model="model.surfaceForm"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          id="study-siglum-legam-group"
          label="Study Siglum LEGaM"
          label-for="study-siglum-legam"
        >
          <!-- <b-form-input
            id="study-siglum-legam"
            v-model="model.studySiglumLegam"
            placeholder="Study Siglum LEGaM"
          ></b-form-input> -->
          <StudySelect @select="selectStudy" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="location-group"
          label="Location"
          label-for="location"
        >
          <LocationSelect @select="selectLocation" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      id="url-group"
      label="URL"
      label-for="url"
    >
      <b-form-input
        id="url"
        v-model="model.url"
        placeholder="URL"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="public-comment-sense-group"
      label="Public Comment Sense"
      label-for="public-comment-sense"
    >
      <b-form-textarea
        id="public-comment-sense"
        placeholder="Public Comment Sense"
        v-model="model.publicCommentSense"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="public-comment-spelling-group"
      label="Public Comment Spelling"
      label-for="public-comment-spelling"
    >
      <b-form-textarea
        id="public-comment-spelling"
        placeholder="Public Comment Spelling"
        v-model="model.publicCommentSpelling"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-group"
      label="Comment"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          id="date-spec-group"
          label="Date Spec"
          label-for="date-spec"
        >
          <b-form-input
            id="date-spec"
            v-model="model.dateSpec"
            placeholder="Date Spec"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="date-num-group"
          label="Date Num"
          label-for="date-num"
        >
          <b-form-input
            id="date-num"
            type="date"
            v-model="model.dateNum"
            placeholder="Date Num"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      id="position-group"
      label="Position"
      label-for="position"
    >
      <b-form-input
        id="position"
        v-model="model.position"
        placeholder="Position"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="part-of-speech-group"
      label="Part Of Speech"
      label-for="part-of-speech"
    >
      <b-form-input
        id="part-of-speech"
        v-model="model.partOfSpeech"
        placeholder="Part Of Speech"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="headword-group"
      label="Headword"
      label-for="headword"
    >
      <b-form-textarea
        id="headword"
        placeholder="Headword"
        v-model="model.headword"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="context-group"
      label="Context"
      label-for="context"
    >
      <b-form-textarea
        id="context"
        placeholder="Context"
        v-model="model.context"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <!-- <b-form-group
      id="is-validated-group"
      label="Is Validated"
      label-for="is-validated"
    >
      <b-form-checkbox
        id="is-validated"
        v-model="model.isValidated"
      ></b-form-checkbox>
    </b-form-group> -->
  </div>
</template>

<script>
  import HelpStore from '@/store/helpstore.js'
  import StudySelect from '@/components/Bibliography/StudySelect.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'

  export default {
    name: 'ReferenceNew',
    props: ['reference'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.reference ? this.reference : {
          id: null,
          lemmaId: null,
          locationId: null,
          studies: [],
          publicCommentSense: null,
          publicCommentSpelling: null,
          surfaceForm: null,
          headword: null,
          siglum: null,
          dateSpec: null,
          dateNum: null,
          partOfSpeech: null,
          position: null,
          context: null,
          comment: null,
          url: null
        })
      }
    },
    components: {
      StudySelect,
      LocationSelect,
      LemmaSelect
    },
    mounted(){
      HelpStore.item = this.model
    },
    methods: {
      selectStudy(study){
        this.model.studies = []
        if (study){
          this.model.studies = [study]
        }
        HelpStore.item = this.model
      },
      selectLocation(location){
        this.model.locationId = null
        this.model.location = null
        if (location){
          this.model.locationId = location.id
          this.model.location = location
        }
        HelpStore.item = this.model
      },
      selectLemma(lemma){
        this.model.lemmaId = null
        this.model.lemma = null
        if (lemma){
          this.model.lemmaId = lemma.id
          this.model.lemma = lemma
        }
        HelpStore.item = this.model
      }
    }
  }
</script>
