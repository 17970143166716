<template>
  <div class="clearfix">
    <b-table striped small hover :items="referencesData" :per-page="perPage" :current-page="currentPage">
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'ReferenceDetails', params: {id: data.item.id} }">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
      <template #cell(display)="data">
        <span v-html="data.item.display"></span>
      </template>
      <template #cell(type)="data">
        <span v-html="data.item.type"></span>
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'ReferenceTable',
    props: ['references', 'editMode', 'showTotal'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.references.length,
        perPage: 10,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    computed: {
      referencesData(){
        return this.references.map(reference => {
          return {
            id: reference.id,
            display: reference.display,
            action: {
              id: reference.id
            }
          }
        })
      }
    },
    methods: {
      deleteRow(event, referenceId){
        event.preventDefault()
        this.$emit('delete', referenceId)
      }
    }
  }
</script>
